import React, { FunctionComponent } from "react";

import Layout from "../common/layout/layout";
import SEO from "../common/seo";
import Navigation from "../common/navigation";

const IndexPage: FunctionComponent = () => (
  <Layout vertical>
    <SEO title="Home" />
    <Navigation
      compact
      links={[
        { url: "/works/", text: "Works" },
        { url: "/about/", text: "About" },
        { url: "/contact/", text: "Contact" },
      ]}
    />
  </Layout>
);

export default IndexPage;
